import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import Home from '../views/home/Home.vue'
// import Login from '../views/auth/Login.vue'
// import Forgot from '../views/auth/Forgot.vue'
// import Reset from '../views/auth/Reset.vue'
// import Register from '../views/auth/Register.vue'
// import Change from '../views/auth/Change.vue'
// import Document from '../views/eCatalogue/Document.vue'
// import CatalogueList from '../views/eCatalogue/CatalogueList.vue'
// import CatalogueDetail from '../views/eCatalogue/CatalogueDetail.vue'
// import CalendarView from '../views/meetingActivity/calendarView/CalendarView.vue'
// import RequestList from '../views/meetingActivity/requestList/RequestList.vue'
// import Approval from '../views/meetingActivity/requestList/Approval.vue'
// import BookingForm from '../views/meetingActivity/booking/BookingForm.vue'
// import MyBooking from '../views/meetingActivity/booking/MyBooking.vue'
// import MyBookingDocument from '../views/meetingActivity/booking/MyBookingDocument'
// import RbsDirectConfirm from '../views/meetingActivity/directConfirm/Confirm'
// import RbsSchedule from '../views/meetingActivity/publicSchedule/Schedule'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "Login" */ '../views/auth/Login.vue'),
    meta: { requiresVisitor: true }
  },
  {
    path: '/oauth/:param',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "Login" */ '../views/auth/Login.vue')
    // meta: { requiresVisitor: true }
  },
  {
    path: '/register',
    name: 'register',
    component: () =>
      import(/* webpackChunkName: "Register" */ '../views/auth/Register.vue'),
    meta: { requiresVisitor: true }
  },
  {
    path: '/forgot-password',
    name: 'forgot',
    component: () =>
      import(/* webpackChunkName: "Forgot" */ '../views/auth/Forgot.vue'),
    meta: { requiresVisitor: true }
  },
  {
    path: '/reset-password/:token',
    name: 'reset',
    component: () =>
      import(/* webpackChunkName: "Reset" */ '../views/auth/Reset.vue'),
    meta: { requiresVisitor: true }
  },
  {
    path: '/change-password',
    name: 'change',
    component: () =>
      import(/* webpackChunkName: "Change" */ '../views/auth/Change.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/e-catalogue/document',
    name: 'document',
    component: () =>
      import(
        /* webpackChunkName: "Document" */ '../views/eCatalogue/Document.vue'
      ),
    meta: { requiresAuth: true }
  },
  {
    path: '/e-catalogue/catalogue-list',
    name: 'catalogueList',
    component: () =>
      import(
        /* webpackChunkName: "CatalogueList" */ '../views/eCatalogue/CatalogueList.vue'
      ),
    meta: { requiresAuth: true }
  },
  {
    path: '/e-catalogue/vendor-list',
    name: 'vendorList',
    component: () =>
      import(
        /* webpackChunkName: "CatalogueList" */ '../views/eCatalogue/VendorList.vue'
      ),
    meta: { requiresAuth: true }
  },
  {
    path: '/e-catalogue/catalogue-detail',
    name: 'catalogueDetail',
    component: () =>
      import(
        /* webpackChunkName: "CatalogueDetail" */ '../views/eCatalogue/CatalogueDetail.vue'
      ),
    meta: { requiresAuth: true }
  },
  {
    path: '/e-catalogue/cart',
    name: 'catalogueCart',
    component: () => import('../views/eCatalogue/Cart'),
    meta: { requiresAuth: true }
    // meta: { requiresVisitor: true }
  },
  {
    path: '/e-catalogue/:path/fpb',
    name: 'catalogueFPB',
    component: () => import('../views/eCatalogue/Fpb'),
    meta: { requiresAuth: true }
    // meta: { requiresVisitor: true }
  },
  {
    path: '/e-catalogue/:path/fpb',
    name: 'catalogueFPB',
    component: () => import('../views/eCatalogue/Fpb'),
    meta: { requiresAuth: true }
    // meta: { requiresVisitor: true }
  },
  {
    path: '/e-catalogue/:path/fpb/:id',
    name: 'catalogueFPB',
    component: () => import('../views/eCatalogue/Fpb'),
    meta: { requiresAuth: true }
    // meta: { requiresVisitor: true }
  },
  {
    path: '/e-catalogue/fpb-list',
    name: 'catalogueFPBList',
    component: () => import('../views/eCatalogue/FpbList'),
    meta: { requiresAuth: true }
    // meta: { requiresVisitor: true }
  },
  {
    path: '/e-catalogue/fpb/project-item',
    name: 'catalogueFPBProjectItem',
    component: () => import('../views/eCatalogue/fpb/FpbProjectItem'),
    meta: { requiresAuth: true }
    // meta: { requiresVisitor: true }
  },
  {
    path: '/e-catalogue/project',
    name: 'catalogueProjectList',
    component: () => import('../views/eCatalogue/project/ProjectList'),
    meta: { requiresAuth: true }
    // meta: { requiresVisitor: true }
  },
  {
    path: '/e-catalogue/project/submission',
    name: 'catalogueProjectSubmission',
    component: () => import('../views/eCatalogue/project/SubmissionForm'),
    meta: { requiresAuth: true }
    // meta: { requiresVisitor: true }
  },
  {
    path: '/e-catalogue/project/detail/:id',
    name: 'catalogueProjectDetail',
    component: () => import('../views/eCatalogue/project/Detail'),
    meta: { requiresAuth: true }
    // meta: { requiresVisitor: true }
  },
  {
    path: '/e-catalogue/payreq',
    name: 'cataloguePayreq',
    component: () => import('../views/eCatalogue/payreq/PayreqList'),
    meta: { requiresAuth: true }
    // meta: { requiresVisitor: true }
  },
  {
    path: '/e-catalogue/payreq/:id',
    name: 'cataloguePayreqDetail',
    component: () => import('../views/eCatalogue/payreq/Detail'),
    meta: { requiresAuth: true }
    // meta: { requiresVisitor: true }
  },
  {
    path: '/e-catalogue/goodsreceipt',
    name: 'catalogueGoodReceipt',
    component: () => import('../views/eCatalogue/goodsreceipt/GrList'),
    meta: { requiresAuth: true }
    // meta: { requiresVisitor: true }
  },
  {
    path: '/e-catalogue/goodsreceipt/:id',
    name: 'catalogueGoodReceiptDetail',
    component: () => import('../views/eCatalogue/goodsreceipt/Detail'),
    meta: { requiresAuth: true }
    // meta: { requiresVisitor: true }
  },
  {
    path: '/e-catalogue/pjca',
    name: 'cataloguePJCA',
    component: () => import('../views/eCatalogue/pjca/PjcaList'),
    meta: { requiresAuth: true }
    // meta: { requiresVisitor: true }
  },
  {
    path: '/e-catalogue/pjca/:id',
    name: 'cataloguePJCADetail',
    component: () => import('../views/eCatalogue/pjca/Detail'),
    meta: { requiresAuth: true }
    // meta: { requiresVisitor: true }
  },
  {
    path: '/e-catalogue/rab',
    name: 'catalogueRABList',
    component: () => import('../views/eCatalogue/rab/RabList'),
    meta: { requiresAuth: true }
    // meta: { requiresVisitor: true }
  },
  {
    path: '/e-catalogue/rab/submission',
    name: 'catalogueRABSubmission',
    component: () => import('../views/eCatalogue/rab/SubmissionForm'),
    meta: { requiresAuth: true }
    // meta: { requiresVisitor: true }
  },
  {
    path: '/e-catalogue/rab/detail/:id',
    name: 'catalogueRabDetail',
    component: () => import('../views/eCatalogue/rab/Detail'),
    meta: { requiresAuth: true }
    // meta: { requiresVisitor: true }
  },

  {
    path: '/meeting-activity/calendar-view',
    name: 'calendarView',
    component: () =>
      import(
        /* webpackChunkName: "CalendarView" */ '../views/meetingActivity/calendarView/CalendarView.vue'
      ),
    meta: { requiresAuth: true }
  },
  {
    path: '/meeting-activity/request-list',
    name: 'request_list',
    component: () =>
      import(
        /* webpackChunkName: "RequestList" */ '../views/meetingActivity/requestList/RequestList.vue'
      ),
    meta: { requiresAuth: true }
  },
  {
    path: '/meeting-activity/request-list/approval',
    name: 'approval',
    component: () =>
      import(
        /* webpackChunkName: "Approval" */ '../views/meetingActivity/requestList/Approval.vue'
      ),
    meta: { requiresAuth: true }
  },
  {
    path: '/meeting-activity/my-booking',
    name: 'myBooking',
    component: () =>
      import(
        /* webpackChunkName: "MyBooking" */ '../views/meetingActivity/booking/MyBooking.vue'
      ),
    meta: { requiresAuth: true }
  },
  {
    path: '/meeting-activity/booking-form',
    name: 'bookingForm',
    component: () =>
      import(
        /* webpackChunkName: "BookingForm" */ '../views/meetingActivity/booking/BookingForm.vue'
      ),
    meta: { requiresAuth: true }
  },
  {
    path: '/meeting-activity/my-booking/document',
    name: 'mybookingdocument',
    component: () =>
      import(
        /* webpackChunkName: "MyBookingDocument" */ '../views/meetingActivity/booking/MyBookingDocument'
      ),
    meta: { requiresAuth: true }
  },
  {
    path: '/rbs/confirm/:token',
    name: 'rbsdirectconfirm',
    component: () =>
      import(
        /* webpackChunkName: "RbsDirectConfirm" */ '../views/meetingActivity/directConfirm/Confirm'
      ),
    meta: { requiresPublic: true }
  },
  {
    path: '/rbs/schedule/:room_id',
    name: 'rbsschedule',
    component: () =>
      import(
        /* webpackChunkName: "RbsSchedule" */ '../views/meetingActivity/publicSchedule/Schedule'
      ),
    meta: { requiresPublic: true }
  },

  {
    path: '/archive-management',
    name: 'archivemanagement',
    component: () => import('../views/archiveManagement/dashboard/Dash'),
    meta: { requiresAuth: true }
  },
  {
    path: '/archive-management/document-list',
    name: 'documentlist',
    component: () => import('../views/archiveManagement/archive/DocumentList'),
    meta: { requiresAuth: true }
  },
  {
    path: '/archive-management/loaning-list',
    name: 'loaninglist',
    component: () => import('../views/archiveManagement/archive/LoaningList'),
    meta: { requiresAuth: true }
  },
  {
    path: '/archive-management/loaning-list/form-loan',
    name: 'loaningform',
    component: () =>
      import('../components/archive-management/loaning/FormLoan'),
    meta: { requiresAuth: true }
  },
  {
    path: '/archive-management/loaning-list/detail',
    name: 'loaningdetail',
    component: () => import('../components/archive-management/loaning/Detail'),
    meta: { requiresAuth: true }
  },
  {
    path: '/archive-management/document-category',
    name: 'documentcategory',
    component: () =>
      import('../views/archiveManagement/master/DocumentCategory'),
    meta: { requiresAuth: true }
  },
  {
    path: '/archive-management/document-subcategory',
    name: 'documentsubcategory',
    component: () =>
      import('../views/archiveManagement/master/DocumentSubCategory'),
    meta: { requiresAuth: true }
  },
  {
    path: '/archive-management/document-release',
    name: 'documentrelease',
    component: () => import('../views/archiveManagement/archive/ReleaseList'),
    meta: { requiresAuth: true }
  },
  {
    path: '/archive-management/document-release/form-release',
    name: 'documentformrelease',
    component: () =>
      import('../components/archive-management/release/FormRelease'),
    meta: { requiresAuth: true }
  },
  {
    path: '/archive-management/document-release/detail',
    name: 'documentreleasedetail',
    component: () => import('../components/archive-management/release/Detail'),
    meta: { requiresAuth: true }
  },
  {
    path: '/archive-management/sop',
    name: 'documentsop',
    component: () => import('../views/archiveManagement/archive/sop'),
    meta: { requiresAuth: true }
  },
  {
    path: '/archive-management/repository',
    name: 'documentdepartment',
    component: () => import('../views/archiveManagement/archive/repository'),
    meta: { requiresAuth: true }
  },
  {
    path: '/archive-management/internal-document',
    name: 'documentinternal',
    component: () =>
      import('../views/archiveManagement/archive/InternalDocument'),
    meta: { requiresAuth: true }
  },
  {
    path: '/edoc/confirm/:token',
    name: 'edocdirectconfirm',
    component: () =>
      import(
        /* webpackChunkName: "RbsDirectConfirm" */ '../views/archiveManagement/directConfirm/Confirm'
      ),
    meta: { requiresPublic: true }
  },
  // {
  //   path: '/atd',
  //   name: 'hrdashboard',
  //   component: () => import('../views/hrSystem/dashboard/Dashboard'),
  //   meta: { requiresAuth: true }
  // },
  // {
  //   path: '/atd/attendance',
  //   name: 'attendancereport',
  //   component: () => import('../views/hrSystem/attendance/AttendanceReport'),
  //   meta: { requiresAuth: true }
  // },
  // {
  //   path: '/atd/door_access',
  //   name: 'dooraccessreport',
  //   component: () => import('../views/hrSystem/attendance/DoorAccessReport'),
  //   meta: { requiresAuth: true }
  // },
  // {
  //   path: '/atd/machine',
  //   name: 'machinereport',
  //   component: () =>
  //     import('../views/hrSystem/attendance/AttendanceMachineReport'),
  //   meta: { requiresAuth: true }
  // },
  {
    path: '/asset-management',
    name: 'assetdashboard',
    component: () => import('../views/assetSystem/dashboard/Dashboard'),
    meta: { requiresAuth: true }
  },
  {
    path: '/asset-management/aset-kepemilikan',
    name: 'assetdankepemilikan',
    component: () => import('../views/assetSystem/asset/AssetList'),
    meta: { requiresAuth: true }
  },
  {
    path: '/asset-management/aset-kepemilikan/tambah-aset',
    name: 'formtambahaset',
    component: () => import('../views/assetSystem/asset/FormAdd'),
    meta: { requiresAuth: true }
  },
  {
    path: '/asset-management/aset-kepemilikan/detail',
    name: 'detailaset',
    component: () => import('../views/assetSystem/asset/DetailAset'),
    meta: { requiresAuth: true }
  },
  {
    path: '/asset-management/aset-kepemilikan/lot/detail/:id',
    name: 'detaillot',
    component: () => import('../views/assetSystem/asset/DetailLot'),
    meta: { requiresAuth: true }
  },
  {
    path: '/asset-management/pemindahan-aset',
    name: 'pemindahanaset',
    component: () => import('../views/assetSystem/movement/MovementList'),
    meta: { requiresAuth: true }
  },
  {
    path: '/asset-management/form-pemindahan',
    name: 'formpemindahanaset',
    component: () => import('../views/assetSystem/movement/MovementForm'),
    meta: { requiresAuth: true }
  },
  {
    path: '/asset-management/detail-penerimaan/:id',
    name: 'deetailpenerimaan',
    // component: () => import('../views/assetSystem/movement/ReceiveDetail'),
    component: () => import('../views/assetSystem/movement/components/Detail'),
    meta: { requiresAuth: true }
  },
  {
    path: '/asset-management/penerimaan-aset',
    name: 'penerimaanaset',
    component: () => import('../views/assetSystem/acceptance/AcceptanceList'),
    meta: { requiresAuth: true }
  },
  {
    path: '/asset-management/stock-report',
    name: 'asetstockreport',
    component: () => import('../views/assetSystem/stockReport/StockReport'),
    meta: { requiresAuth: true }
  },
  {
    path: '/asset-management/kategori',
    name: 'kategoriaset',
    component: () => import('../views/assetSystem/master/AssetCategory'),
    meta: { requiresAuth: true }
  },
  {
    path: '/asset-management/penerimaan-aset',
    name: 'penerimaanaset',
    component: () => import('../views/assetSystem/acceptance/AcceptanceList'),
    meta: { requiresAuth: true }
  },
  {
    path: '/asset-management/terjual',
    name: 'asetterjual',
    component: () => import('../views/assetSystem/sold/SoldList'),
    meta: { requiresAuth: true }
  },
  {
    path: '/detail-persetujuan/:id',
    name: 'detailpersetujuan',
    component: () => import('../views/home/components/Approvement'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/dashboard',
    name: 'hrdashboard',
    component: () => import('../views/hrSystem/dashboard/Dashboard'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/ms/leave_category',
    name: 'leavecategory',
    component: () => import('../views/hrSystem/master/LeaveCategory'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/ms/permission_category',
    name: 'permissioncategory',
    component: () => import('../views/hrSystem/master/PermissionCategory'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/ms/activity_customer',
    name: 'activitycustomer',
    component: () => import('../views/hrSystem/master/ActivityCustomer'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/ms/attendance_area',
    name: 'attendancearea',
    component: () => import('../views/hrSystem/master/AttendanceArea'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/ms/position',
    name: 'position',
    component: () => import('../views/hrSystem/master/Position'),
    meta: { requiresAuth: true }
  },
  // {
  //   path: '/hr/ms/division',
  //   name: 'division',
  //   component: () => import('../views/hrSystem/master/Division'),
  //   meta: { requiresAuth: true }
  // },
  {
    path: '/hr/ms/department',
    name: 'department',
    component: () => import('../views/hrSystem/master/Department'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/ms/provinsi',
    name: 'provinsi',
    component: () => import('../views/hrSystem/master/region/Provinsi'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/ms/kota',
    name: 'kota',
    component: () => import('../views/hrSystem/master/region/Kota'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/ms/kecamatan',
    name: 'kecamatan',
    component: () => import('../views/hrSystem/master/region/Kecamatan'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/ms/kelurahan',
    name: 'kelurahan',
    component: () => import('../views/hrSystem/master/region/Kelurahan'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/ms/holidaysetting',
    name: 'holidaysetting',
    component: () => import('../views/hrSystem/master/HolidaySetting'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/ms/position_hierarchy',
    name: 'hierarchy',
    component: () =>
      import('../views/hrSystem/master/hierarchy/HierarchyPositionSettings'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/ms/approval_hierarchy',
    name: 'approval',
    component: () => import('../views/hrSystem/master/Approval'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/ms/authority',
    name: 'authority',
    component: () => import('../views/hrSystem/master/Authority'),
    meta: { requiresAuth: true }
  },
  // {
  //   path: '/hr/ms/group',
  //   name: 'group',
  //   component: () => import('../views/hrSystem/master/schedule/MasterGroup'),
  //   meta: { requiresAuth: true }
  // },
  {
    path: '/hr/ms/shift',
    name: 'shift',
    component: () => import('../views/hrSystem/master/schedule/MasterShift'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/ms/shift_schedule',
    name: 'shiftSchedule',
    component: () => import('../views/hrSystem/master/schedule/ScheduleShift'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/ms/shift_category',
    name: 'shiftcategory',
    component: () => import('../views/hrSystem/master/schedule/ShiftCategory'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/employee',
    name: 'employee',
    component: () => import('../views/hrSystem/employee/Employee'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/employee/registration_form',
    name: 'employeeregistrationform',
    component: () => import('../views/hrSystem/employee/RegistrationForm'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/employee/detail/:id',
    name: 'employeedetail',
    component: () => import('../views/hrSystem/employee/Detail'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/attendance_report',
    name: 'attendancereport',
    component: () => import('../views/hrSystem/attendance/AttendanceReport'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hrs/presence_report',
    name: 'presencereport',
    component: () => import('../views/hrSystem/attendance/AttendancePerUser'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/access_door_report',
    name: 'dooraccessreport',
    component: () => import('../views/hrSystem/attendance/DoorAccessReport'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/position_hierarchy',
    name: 'hierarchyposition',
    component: () => import('../views/hrSystem/hierarchy/HierarchyPosition'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/leave',
    name: 'leave',
    component: () => import('../views/hrSystem/leave/Leave'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/leave/submission',
    name: 'leavesubmission',
    component: () => import('../views/hrSystem/leave/SubmissionForm'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/leave/detail/:id',
    name: 'leavedetail',
    component: () => import('../views/hrSystem/leave/Detail'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/permission',
    name: 'permission',
    component: () => import('../views/hrSystem/permission/Permission'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/permission/submission',
    name: 'permissionsubmission',
    component: () => import('../views/hrSystem/permission/SubmissionForm'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/permission/detail/:id',
    name: 'permissiondetail',
    component: () => import('../views/hrSystem/permission/Detail'),
    meta: { requiresAuth: true }
  },
  {
    path: '/notifications',
    name: 'notification',
    component: () => import('../views/home/components/Notifications'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/activity',
    name: 'activity',
    component: () => import('../views/hrSystem/activity/Activity'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/activity/submission',
    name: 'activitysubmission',
    component: () => import('../views/hrSystem/activity/SubmissionForm'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/activity/detail/:id',
    name: 'activitydetail',
    component: () => import('../views/hrSystem/activity/Detail'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hrs/attendance_log',
    name: 'attendancelog',
    component: () =>
      import('../views/hrSystem/attendance/AttendanceMachineReport'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/payroll/additional',
    name: 'payrolladditional',
    component: () => import('../views/hrSystem/payroll/additional/Additional'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/payroll/additional/submission',
    name: 'payrolladditionalsubmisison',
    component: () => import('../views/hrSystem/payroll/additional/Submission'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/payroll/additional/detail/:id',
    name: 'payrolladditionaldetail',
    component: () => import('../views/hrSystem/payroll/additional/Detail'),
    meta: { requiresAuth: true }
  },
  // {
  //   path: '/hr/payroll/generate',
  //   name: 'generatepayroll',
  //   component: () => import('../views/hrSystem/payroll/Generate'),
  //   meta: { requiresAuth: true }
  // },
  {
    path: '/hr/payroll',
    name: 'payroll',
    component: () => import('../views/hrSystem/payroll/Payroll'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/payroll/detail/:id',
    name: 'payrolldetail',
    component: () => import('../views/hrSystem/payroll/Detail'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/payroll/slip/detail/:id',
    name: 'payrollslipdetail',
    component: () => import('../views/hrSystem/payroll/Slip'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/ms/overtime',
    name: 'msovertime',
    component: () => import('../views/hrSystem/master/overtime/Overtime'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/ms/overtime/rounding',
    name: 'msovertimerounding',
    component: () =>
      import('../views/hrSystem/master/overtime/OvertimeRounding'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/ms/overtime/multiplier',
    name: 'msovertimemultiplier',
    component: () =>
      import('../views/hrSystem/master/overtime/OvertimeMultiplier'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/loan',
    name: 'loan',
    component: () => import('../views/hrSystem/loan/Loan'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/cop',
    name: 'cop',
    component: () => import('../views/hrSystem/cop/Cop'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/overtime',
    name: 'overtime',
    component: () => import('../views/hrSystem/overtime/Overtime'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/overtime/detail/:id',
    name: 'overtimedetail',
    component: () => import('../views/hrSystem/overtime/Detail_old'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/overtime/document/detail/:id',
    name: 'overtimedocumentdetail',
    component: () => import('../views/hrSystem/overtime/Detail'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/ms/bpjsprovision',
    name: 'bpjsprovision',
    component: () => import('../views/hrSystem/master/bpjs/Provision'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/ms/config',
    name: 'configuration',
    component: () => import('../views/hrSystem/master/Config'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/ms/payrollcomponent',
    name: 'payrollcomponent',
    component: () => import('../views/hrSystem/master/payroll/Component'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/recruitment',
    name: 'adminrecruitment',
    component: () => import('../views/hrSystem/recruitment/Candidate'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/ms/jobvacancy',
    name: 'ms_jobvacancy',
    component: () => import('../views/hrSystem/recruitment/JobVacancy'),
    meta: { requiresAuth: true }
  },
  {
    path: '/hr/ms/payroll/template',
    name: 'ms_payrolltemplate',
    component: () => import('../views/hrSystem/master/payroll/Template'),
    meta: { requiresAuth: true }
  },
  {
    path: '/mms',
    name: 'mmsdashboard',
    component: () => import('../views/mms/Dashboard'),
    meta: { requiresAuth: true }
  },
  {
    path: '/mms/oee',
    name: 'oee',
    component: () => import('../views/mms/oee/Oee'),
    meta: { requiresAuth: true }
  },
  {
    path: '/mms/oee/detail',
    name: 'oeedetail',
    component: () => import('../views/mms/oee/Detail'),
    meta: { requiresAuth: true }
    // meta: { requiresVisitor: true }
  },
  {
    path: '/mms/oee/detail/:id',
    name: 'oeedetail',
    component: () => import('../views/mms/oee/Detail'),
    meta: { requiresAuth: true }
    // meta: { requiresVisitor: true }
  },
  {
    path: '/mms/mastermachine',
    name: 'mmsmastermachine',
    component: () => import('../views/mms/master/MasterMachine'),
    meta: { requiresAuth: true }
  },
  {
    path: '/mms/masterproperty',
    name: 'mmsmasterproperty',
    component: () => import('../views/mms/master/MasterProperty'),
    meta: { requiresAuth: true }
  },
  {
    path: '/mms/mastercategory',
    name: 'mmsmastercategory',
    component: () => import('../views/mms/master/MasterCategory'),
    meta: { requiresAuth: true }
  },
  {
    path: '/mms/masterreasone',
    name: 'mmsmasterreasone',
    component: () => import('../views/mms/master/MasterReasone'),
    meta: { requiresAuth: true }
  },
  {
    path: '/expedition',
    name: 'expeditiondashboard',
    component: () => import('../views/expedition/Dashboard'),
    meta: { requiresAuth: true }
    // meta: { requiresVisitor: true }
  },
  {
    path: '/expedition/ordertransaction',
    name: 'ordertransaction',
    component: () => import('../views/expedition/transaction/OrderTransaction'),
    meta: { requiresAuth: true }
    // meta: { requiresVisitor: true }
  },
  {
    path: '/expedition/makeorder',
    name: 'ordertransactionform',
    component: () => import('../views/expedition/transaction/MakeOrder'),
    meta: { requiresAuth: true }
    // meta: { requiresVisitor: true }
  },
  {
    path: '/expedition/ordertransaction/detail/:id',
    name: 'ordertransactionform',
    component: () => import('../views/expedition/transaction/Detail'),
    meta: { requiresAuth: true }
    // meta: { requiresVisitor: true }
  },
  {
    path: '/expedition/riterealization',
    name: 'riterealization',
    component: () => import('../views/expedition/report/RiteRealization'),
    meta: { requiresAuth: true }
    // meta: { requiresVisitor: true }
  },
  {
    path: '/expedition/qtyrealization',
    name: 'qtyrealization',
    component: () => import('../views/expedition/report/QtyRealization'),
    meta: { requiresAuth: true }
    // meta: { requiresVisitor: true }
  },
  {
    path: '/expedition/ms/driver',
    name: 'msdriver',
    component: () => import('../views/expedition/master/Driver'),
    meta: { requiresAuth: true }
    // meta: { requiresVisitor: true }
  },
  {
    path: '/expedition/ms/vehicle',
    name: 'msvehicle',
    component: () => import('../views/expedition/master/Vehicle'),
    meta: { requiresAuth: true }
    // meta: { requiresVisitor: true }
  },
  {
    path: '/expedition/ms/vehicletype',
    name: 'msvehicletype',
    component: () => import('../views/expedition/master/VehicleType'),
    meta: { requiresAuth: true }
    // meta: { requiresVisitor: true }
  },
  {
    path: '/expedition/ms/fueltype',
    name: 'msfueltype',
    component: () => import('../views/expedition/master/FuelType'),
    meta: { requiresAuth: true }
    // meta: { requiresVisitor: true }
  },
  {
    path: '/expedition/ms/zone',
    name: 'mszone',
    component: () => import('../views/expedition/master/Zone'),
    meta: { requiresAuth: true }
    // meta: { requiresVisitor: true }
  },
  {
    path: '/expedition/ms/product',
    name: 'msproduct',
    component: () => import('../views/expedition/master/Product'),
    meta: { requiresAuth: true }
    // meta: { requiresVisitor: true }
  },
  {
    path: '/expedition/ms/customer',
    name: 'mscustomer',
    component: () => import('../views/expedition/master/Customer'),
    meta: { requiresAuth: true }
    // meta: { requiresVisitor: true }
  },
  {
    path: '/expedition/ms/subcustomer',
    name: 'mssubcustomer',
    component: () => import('../views/expedition/master/SubCustomer'),
    meta: { requiresAuth: true }
    // meta: { requiresVisitor: true }
  },
  {
    path: '/expedition/ms/downline',
    name: 'msdownline',
    component: () => import('../views/expedition/master/Downline'),
    meta: { requiresAuth: true }
    // meta: { requiresVisitor: true }
  },
  {
    path: '/expedition/ms/pricelist',
    name: 'mspricelist',
    component: () => import('../views/expedition/master/PriceList'),
    meta: { requiresAuth: true }
    // meta: { requiresVisitor: true }
  },
  {
    path: '/expedition/ms/travelcost',
    name: 'mstravelcost',
    component: () => import('../views/expedition/master/TravelCost'),
    meta: { requiresAuth: true }
    // meta: { requiresVisitor: true }
  }
  // {
  //   path: '/public/career/information',
  //   name: 'career',
  //   component: () => import('../views/tracker/Dashboard'),
  //   meta: { requiresVisitor: true }
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      const position = {}
      if (to.hash) {
        position.selector = to.hash
        return false
      }
    }
  },
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.getLogin) {
      next({
        path: '/login'
      })
    } else {
      // store.dispatch('setWebFcmToken')
      next()
    }
  } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
    if (store.getters.getLogin) {
      next({
        path: '/'
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
